import React from 'react';
import '../styles/Services.css';
import '../styles/mobile/ServicesMobile.css';
import '../styles/ServiceItems.css'

//Images
import OilIcon from '../images/engine_1924138.png';
import BrakeIcon from '../images/tools-utensils_453547.png';
import ExhaustIcon from '../images/exhaust-pipe_290063.png'
import ACIcon from '../images/snowflakes_3431449.png';
import Speaker from '../images/speaker.png'
import Diagnostic from '../images/diagnostic.png'

//Components
import ServicesItem from './ServicesItems';


// const services = ['A/C Repair', 'Brake Repair', 'Check Engine Light Diagnostics', 'Engine Repair',
//     'Oil Change Service', ' Suspension Repair', 'Tire & Wheel Service', 'Transmission Repair'
// ];

const Services = () => {
    return (
        <div className="Services-container" id='Services'>

            <div className="Services-intro">
                
                <h1 className="introTitle">Welcome to J&A's Auto Services!</h1>
                <p className="introDescription">Welcome to J&A's Auto Services, your premier destination for top-quality automotive care in Marlborough. At J&A's Auto Services, we're more than just a mechanic shop. We're your partners in keeping your vehicle running smoothly, efficiently, and safely.</p>

            </div>

            <div className="Services-list">
                <ServicesItem
                    route='/services/brakes'
                    image={BrakeIcon}
                    title="Brakes"
                    description="Getting your brakes changed on your vehicle is essential for your safety and the proper functioning of your car"
                />

                <ServicesItem
                    route='/services/oil'
                    image={OilIcon}
                    title="Oil Changes"
                    description="Getting regular oil changes is essential for the health and longevity of your vehicle"
                />

                <ServicesItem
                    route='/services/exhaust&muffler'
                    image={ExhaustIcon}
                    title="Exhaust and Mufflers"
                    description="Exhaust and muffler work is important for safety, performance and environmental considerations"
                />

                <ServicesItem
                    route='/services/AC'
                    image={ACIcon}
                    title="A/C Repairs"
                    description="Getting A/C repairs done in your car is important for several reasons, providing comfort, safety, and overall enjoyment during your drives"
                />

                <ServicesItem
                    route='/services/AC'
                    image={Speaker}
                    title="Audio Systems"
                    description="Elevate your daily commute, our audio services provide the clarity and depth your vehicle deserves."
                />

                <ServicesItem
                    route='/services/AC'
                    image={Diagnostic}
                    title="Diagnostic Expertise"
                    description="We excel in accurately diagnosing your vehicle's issues, saving you time and money by targeting the problem precisely."
                />

            </div>
            
        </div>
    );
};

export default Services;
import React from "react";
import "../styles/Map.css";
import "../styles/mobile/MapMobile.css";

//Garage Address: 83 Mill Street, Marlborough MA 01752 (Bay 21 and 22)

const Map = () => {
    return (

        <>  
            <div className="Map-container">
                {/* <div className="Map-info">
                    <h1>Get In Touch</h1>

                    <div className="Map-info-details">
                        <h3>Our Location</h3>
                        <h4>83 Mill Street, Marlborough MA, 01752</h4>
                        <h4>Garages 1 & 2</h4>
                        <h4><a href="https://www.google.com/maps/search/?api=1&query=42.33348008706724%2C-71.54223846863007" className="Map-address" target="_blank"><button>Visit Us!</button></a></h4>

                        <h3>Call Us!</h3>
                        <h4><a href="tel://1-401-309-6088" className="Map-phone"><button>Call Us!</button></a></h4>
                    </div>
                    
                </div> */}
                <div className="Map">
                    <iframe src="https://www.openstreetmap.org/export/embed.html?bbox=-71.5439558029175%2C42.332431594758006%2C-71.54041528701784%2C42.33423593788173&amp;layer=mapnik&amp;marker=42.3333337727904%2C-71.54218554496765" style={{border: "1px solid black"}}></iframe><br/><small><a href="https://www.openstreetmap.org/?mlat=42.33333&amp;mlon=-71.54219#map=19/42.33333/-71.54219">View Larger Map</a></small>
                </div>
            </div>
        </>
    )
}

export default Map;
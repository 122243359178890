import React from "react";

//Mobile stylesheet
import '../styles/mobile/ServicesItemsMobile.css';

const ServicesItem = ({image, title, description}) => {
    return (
        <>

            <div className="Service-icon flex">

                <svg  className="Service-icon icon">
                    <image href={image}/>
                </svg>

                

                <div className="Service-description">
                    <h3 className="Services-title">{title}</h3>
                    <p>{description}</p>
                </div>
                
            </div>
            
        </>
    )
};

export default ServicesItem;
import React from "react";

//Components
import Banner from "../images/Banner.jpg"
import Map from "./Map";


//Styles
import '../styles/Home.css'
import '../styles/mobile/HomeMobile.css'

//Components
import Services from './Services';
import About from "./About";
import InfoBanner from "./InfoBanner"


const Home = () => {
    return(
        <div className="Home" id='home'>
            <div className="Banner">
                <h1 className="Banner-title">J&A's Auto Services</h1>
                <p>Providing Marlborough with premium car services</p>
                {/* <button className="Banner-contact-btn"><a href="#Contact">Get in touch!</a></button> */}
            </div>
            
            <InfoBanner />
            <Services/>
            <About/>
            <Map/>
            
        </div>
    )
};

export default Home;
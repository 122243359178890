import React from 'react';
import '../styles/Footer.css'

const Footer = () => {
    return (
        <>
            <div className="Footer">
                <h3>J&A's Auto Service LLC</h3>
            </div>
        </>
    );
};

export default Footer;
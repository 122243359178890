import React ,{useState} from 'react';

//Styles
import "../styles/InfoItem.css"

const InfoItem = ({header, description, icon}) => {
    return (
        <>
            <div className="InfoItem">
                <h2>{header}</h2>
                {isNaN(description) ? <a href="https://www.google.com/maps/search/?api=1&query=42.33348008706724%2C-71.54223846863007" className="Map-address" target="_blank"><button>{description}</button></a> : <a href="tel://1-401-309-6088" className="InfoItem-phone"><button>{description}</button></a>}
            </div>
        </>
    );
};

export default InfoItem;
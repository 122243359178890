import React from "react";
import '../styles/ServicesPage.css'
import ServicesItem from "./ServicesItems";

//Images
import OilIcon from '../images/engine_1924138.png';
import BrakeIcon from '../images/tools-utensils_453547.png';
import ExhaustIcon from '../images/exhaust-pipe_290063.png'
import ACIcon from '../images/snowflakes_3431449.png';

const ServicePage = () => {
    return (
        <>
            <div className="ServicesBanner">
                <h1 className="ServicesBanner-title">Our Services</h1>
            </div>

            <div className="ServicePage-list">
                <ServicesItem
                    route='/services/brakes'
                    image={BrakeIcon}
                    title="Brakes"
                    description="Getting your brakes changed on your vehicle is essential for your safety and the proper functioning of your car"
                />

                <ServicesItem
                    route='/services/oil'
                    image={OilIcon}
                    title="Oil Changes"
                    description="Getting regular oil changes is essential for the health and longevity of your vehicle"
                />

                <ServicesItem
                    route='/services/exhaust&muffler'
                    image={ExhaustIcon}
                    title="Exhaust and Mufflers"
                    description="Exhaust and muffler work is important for safety, performance and environmental considerations"
                />

                <ServicesItem
                    route='/services/AC'
                    image={ACIcon}
                    title="A/C Repairs"
                    description="Getting A/C repairs done in your car is important for several reasons, providing comfort, safety, and overall enjoyment during your drives"
                />

                <ServicesItem
                    route='/services/AC'
                    image={ACIcon}
                    title="Audio Systems"
                    description="Getting A/C repairs done in your car is important for several reasons, providing comfort, safety, and overall enjoyment during your drives"
                />

            </div>
        </>
    )
}

export default ServicePage;
import React from 'react';

//Components
import Navi from '../components/Navbar';
import Home from '../components/Home';
import About from '../components/About';
import ServicePage from '../components/ServicePage';
import Contact from '../components/Contact';

//React Router
import { Routes, Route } from 'react-router-dom';
import Footer from '../components/Footer';

//Components to add:
/**
 * 
 * Company hours
 * Dynamic component for each service item
 * Contact us page/form
 * 
 */

const AppRoutes = () => {
    return (
        <>
            <Navi/>
            
            <Routes>
                <Route exact path="/" element={<Home/>}/>
                <Route exact path="/services" element={<ServicePage/>} />
            </Routes>

            <Footer />
        </>
    );
};

export default AppRoutes;
import React from 'react';

//Styles
import '../styles/InfoBanner.css'
import '../styles/mobile/InfoBannerMobile.css'

//Comonents
import InfoItem from './InfoItem';

const InfoBanner = () => {
    return (
        <>
            <div className="InfoBanner" id="Contact">

                <div className="InfoBanner-info">
                    <InfoItem 
                        header="Our Location"
                        description="83 Mill Street, Marlborough MA, 01752"
                    />

                    <InfoItem
                        header="Call Us"
                        description="14013096088"
                    />
                </div>
                
            </div>
        </>
    );
};

export default InfoBanner;
import React from "react";

//Styles
import "../styles/About.css";
import "../styles/mobile/AboutMobile.css"
import AboutImage from '../images/laurel-and-michael-evans-c-KDq7nxVdQ-unsplash.jpg';

const About = () => {
    return (
        <>
            <div className="About" id="About">
                <div className="About-banner">
                    <img src={AboutImage} className="banner"/>
                </div>
                
                <div className="About-info">
                  

                    <h2>Why Choose J&A's Auto Services?</h2>
                    <ul>
                        <li>
                            <h3>Comprehensive Services</h3> 
                            From routine maintenance to complex repairs, we offer a wide range of automotive services designed to meet all your needs under one roof.
                        </li>
            
                        <li>
                            <h3>Quality Parts</h3> Your vehicle deserves the best. That's why we use only top-quality, genuine parts to guarantee the longevity and reliability of our repairs.
                        </li>

                        <li>
                            <h3>Transparent Pricing</h3>We believe in fair and transparent pricing. Before any work begins, we provide clear explanations of the required repairs and their associated costs, so you can make informed decisions.
                        </li>
                        
                        <li>
                            <h3>Customer-Centric Approach</h3> At J&A's Auto Services, we put our customers first. We're dedicated to delivering exceptional service, and your satisfaction is our ultimate goal.
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default About;